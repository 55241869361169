<template>
    <Loading :isLoading="isLoading" />
    <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">
        <h1 class="primary-text font-semibold text-2xl md:text-2xl text-thin mt-2 mb-5 md:mb-7"> Solicitud de crédito </h1>
        <p class="label-input primary-text font-light text-xl md:text-xl text-thin mt-2 mb-4 md:mb-6"> Carga Documentos </p>
        <DocumentHandler :documents="documentList" :key="refresh" @upload-file="uploadFile($event)" @delete-file="deleteFile($event)"/>
        <div class="w-full">
            <a id="button_continue" @click="next()" class="md:w-1/3 block bg-mustardDark cursor-pointer px-5 py-4 mx-auto my-8 h-14 md:h-14 rounded text-center font-normal text-xl text-white"> Continuar </a>       
        </div>
    </div>
</template>

<script>

import { ref, onMounted, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

import Loans from "@/classes/Loans.js";
import { getTokenDecoden } from '@/helpers/tokenauth';
import { getAdmissionRequestOrCreate } from "@/api/user";

import Loading from '@/components/Loading/VueLoading.vue';
import Swal from 'sweetalert2';

export default {
    name: 'CreditDocuments',
    setup(){

        const router = useRouter();

        let documents = ref([]);
        
        let refresh = ref(true);

        let isLoading = ref(true);

        let documentList = {
            "response":{
                "data": [
                {
                    "document_id": 0,
                    "required_in": "Inscripción asistida issste",
                    "optional": false,
                    "max_documents_required": 2,
                    "extension_allowed": ".pdf|.png|.PNG|.jpeg", 
                    "url": "",
                    "abbreviation": "IO",
                    "document_name": "",
                    "document_type": "Identificación oficial",
                    "extension": "png",
                    "doument_base64": ""
                },
                {
                    "document_id": 0,
                    "required_in": "Inscripción asistida issste",
                    "optional": false,
                    "max_documents_required": 1,
                    "extension_allowed": ".pdf|.png|.PNG|.jpeg", 
                    "url": "",
                    "abbreviation": "EC",
                    "document_name": "",
                    "document_type": "Estado de Cuenta",
                    "extension": "",
                    "doument_base64": ""
                },
                {
                    "document_id": 0,
                    "required_in": "Inscripción asistida issste",
                    "optional": false,
                    "max_documents_required": 1,
                    "extension_allowed": ".pdf|.png|.PNG|.jpeg", 
                    "url": "",
                    "abbreviation": "CD",
                    "document_name": "",
                    "document_type": "Comprobante de domicilio",
                    "extension": "",
                    "doument_base64": ""
                }
            ]
                
            }
        };

        let loanClassObject = ref(null);

        let creditData = ref(null);

        let creditOwner = ref(null);

        let admissionRequestInformation = ref(null);

        let personalInformation = ref(null);

        let documentsUpload = ref([]);

        onBeforeMount(()=> {
            if(!exitStorage()) goToHome();
            loanClassObject.value = new Loans();
        });

        onMounted(async ()=> {
            loadSessionStorageInformation();
            getPesonalInformation();
            await getAccountInformation();
            isLoading.value = false;
        });

        function uploadFile(value){
            documentList.response.data.forEach(element => {
                if(element.abbreviation == value.newFile.abbreviation){
                    element.document_id = 1;
                    element.document_name = value.newFile.name;
                }
            })

            let newFile = {
                "name": value.newFile.name,
                "extension": value.newFile.extension,
                "abbreviation": value.newFile.abbreviation,
                "credit_request_id":  590,
                "remote_folder": value.newFile.remote_folder,
                "file": value.newFile.file
            };

            documentsUpload.value.push(newFile);
            refresh.value = !refresh.value;
        }

        async function registerCreditRequest() {
            let token = getToken();
            let foncabsaClientId = personalInformation.value.person.client_id;
            let personalInfo = loanClassObject.value;
            return await loanClassObject.value.registerCreditRequest(token, foncabsaClientId, personalInfo).then(resp => {return {status: true, message: resp.data.response.message, data: resp.data.response.result};
            }).catch(err => { return { status: false, message: err.response.data.response.message, data: null } });
        }

        function deleteFile(value){
            let documentIndex = documentList.response.data.map(element => element.abbreviation).indexOf(value.abbreviation);
            documentList.response.data[documentIndex].document_id = 0;
            documentList.response.data[documentIndex].document_name = "";
            
            documentIndex = documentsUpload.value.map(element => element.newFile.abbreviation).indexOf(value.abbreviation);

            delete documentsUpload.value[documentIndex];
            console.log("🚀 ~ file: CreditDocumentsComponent.vue:126 ~ deleteFile ~ documentUpload.value:", documentsUpload.value)
        }

        async function getAccountInformation(){
            
            const admissionRequest = await getAdmissionRequest();
            if(admissionRequest.status){

                let contactInformation = admissionRequest.data.admission_information.contact_info_attributes;

                let cellphone = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == 'celular');
                cellphone = contactInformation[cellphone].value;

                let telephone = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == 'telefono particular');
                telephone = contactInformation[telephone].value;

                let email = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == 'correo');
                email = contactInformation[email].value;

                admissionRequestInformation.value = admissionRequest.data;
            }
        }

        async function getAdmissionRequest(){
            let token = getToken();
            let personId = personalInformation.value.person.id;
            return await getAdmissionRequestOrCreate(token, personId).then(resp => {
                return {status: true, message: resp.data.response.message, data: resp.data.response.admission_request};
            }).catch(err => { return { status: false, message: err.response.data.response.message, data: null } });
        }

        function getToken(){
            return sessionStorage.getItem('login');
        }

        function getPesonalInformation(){
            let information = getTokenDecoden();
            personalInformation.value = information.obj;
            console.log("🚀 ~ file: CreditDocumentsComponent.vue:175 ~ getPesonalInformation ~ personalInformation.value:", personalInformation.value)
        }

        function exitStorage(){
            return sessionStorage.getItem('credit-data') !== undefined && sessionStorage.getItem('credit-owner') !== undefined
        }
        
        function loadSessionStorageInformation(){

            let information = getInformationOfStorage();
            if (information.credit_data == "" || information.credit_owner == "") {
                showMessage('warning', 'Aviso', `Información de crédito no encontrada`); 
                return;
            }

            creditData.value = information.credit_data;
            creditOwner.value = information.credit_owner;

        }

        function getInformationOfStorage(){
            let creditData = JSON.parse(sessionStorage.getItem('credit-data'));
            let creditOwner = JSON.parse(sessionStorage.getItem('credit-owner'));
            
            return {
                credit_data: creditData,
                credit_owner: creditOwner
            }
        }

        function setJSONData(){

            try {
                loanClassObject.value.data.token_auth = getToken();

                loanClassObject.value.data.personal_info.identification_type = admissionRequestInformation.value.admission_information.identification_type;
                loanClassObject.value.data.personal_info.identification_number = admissionRequestInformation.value.admission_information.identification_number;
                loanClassObject.value.data.personal_info.residence_type = personalInformation.value.person.residence_status;
                loanClassObject.value.data.personal_info.recsidence_date = personalInformation.value.person.residence_start;
                loanClassObject.value.data.personal_info.education_level = personalInformation.value.person.education_level;
                loanClassObject.value.data.personal_info.marital_status = personalInformation.value.person.marital_status;
                loanClassObject.value.data.personal_info.regime = personalInformation.value.person.marital_status == "SOLTERO(A)" ? '' : personalInformation.value.person.regime;

                let maritalStatus = personalInformation.value.person.marital_status;
                let { rfc, name, gender, middle_name, maternal_name, paternal_name, birtday } = admissionRequestInformation.value.admission_information.spouse_attributes;
                loanClassObject.value.data.personal_info.spouse.rfc = maritalStatus != "SOLTERO(A)" ? rfc : '';
                loanClassObject.value.data.personal_info.spouse.name = maritalStatus != "SOLTERO(A)" ? name : '';
                loanClassObject.value.data.personal_info.spouse.gender = maritalStatus != "SOLTERO(A)" ? gender : '';
                loanClassObject.value.data.personal_info.spouse.middle_name = maritalStatus != "SOLTERO(A)" ? middle_name : '';
                loanClassObject.value.data.personal_info.spouse.maternal_name = maritalStatus != "SOLTERO(A)" ? maternal_name : '';
                loanClassObject.value.data.personal_info.spouse.paternal_name = maritalStatus != "SOLTERO(A)" ? paternal_name : '';
                loanClassObject.value.data.personal_info.spouse.birtday = maritalStatus != "SOLTERO(A)" ? birtday : '';

                loanClassObject.value.data.personal_info.contact_info = admissionRequestInformation.value.admission_information.contact_info_attributes;
                
                let publicOffice = admissionRequestInformation.value.admission_information.public_office_attributes;
                let publicOfficeIndex = Object.keys(publicOffice).find(element => publicOffice[element].relationship == 'titular');
                publicOffice = publicOffice[publicOfficeIndex];

                let publicOfficeFamily = admissionRequestInformation.value.admission_information.public_office_attributes;
                let publicOfficeFamilyIndex = Object.keys(publicOfficeFamily).find(element => publicOfficeFamily[element].relationship == 'familiar');
                publicOfficeFamily = publicOfficeFamily[publicOfficeFamilyIndex];
                
                let workInformation = admissionRequestInformation.value.admission_information.work_info_attributes;
                loanClassObject.value.data.personal_info.work_info.status = workInformation.status;
                loanClassObject.value.data.personal_info.work_info.category = workInformation.category;
                loanClassObject.value.data.personal_info.work_info.occupation = workInformation.occupation;
                loanClassObject.value.data.personal_info.work_info.supervisor = workInformation.supervisor;
                loanClassObject.value.data.personal_info.work_info.active_since = workInformation.active_since;
                loanClassObject.value.data.personal_info.work_info.business_name = workInformation.business_name;
                loanClassObject.value.data.personal_info.work_info.laboral_status = workInformation.laboral_status;
                loanClassObject.value.data.personal_info.work_info.employee_number = workInformation.employee_number;
                loanClassObject.value.data.personal_info.work_info.principal_occupation = workInformation.principal_occupation;
                loanClassObject.value.data.personal_info.work_info.has_had_public_positions = publicOffice.has_had_public_positions;
                loanClassObject.value.data.personal_info.work_info.any_relative_has_had_public_positions = publicOfficeFamily.has_had_public_positions;
                
                let { city, state, street, suburb, status, postal_code, country, center_key, ext_number, int_number, municipality } = admissionRequestInformation.value.admission_information.workplace_attributes;
                let workplaceName = admissionRequestInformation.value.admission_information.workplace_attributes.name
                loanClassObject.value.data.personal_info.workplace.name = workplaceName;
                loanClassObject.value.data.personal_info.workplace.city = city;
                loanClassObject.value.data.personal_info.workplace.state = state;
                loanClassObject.value.data.personal_info.workplace.street = street;
                loanClassObject.value.data.personal_info.workplace.suburb = suburb;
                loanClassObject.value.data.personal_info.workplace.status = status;
                loanClassObject.value.data.personal_info.workplace.postal_code = postal_code;
                loanClassObject.value.data.personal_info.workplace.country = country;
                loanClassObject.value.data.personal_info.workplace.center_key = center_key;
                loanClassObject.value.data.personal_info.workplace.ext_number = ext_number;
                loanClassObject.value.data.personal_info.workplace.int_number = int_number;
                loanClassObject.value.data.personal_info.workplace.municipality = municipality;

                let workplace = admissionRequestInformation.value.admission_information.workplace_attributes.adress_attributes;
                loanClassObject.value.data.personal_info.workplace.adress.city = workplace.city;
                loanClassObject.value.data.personal_info.workplace.adress.state = workplace.state;
                loanClassObject.value.data.personal_info.workplace.adress.status = workplace.status;
                loanClassObject.value.data.personal_info.workplace.adress.street = workplace.street;
                loanClassObject.value.data.personal_info.workplace.adress.suburb = workplace.suburb;
                loanClassObject.value.data.personal_info.workplace.adress.country = workplace.country;
                loanClassObject.value.data.personal_info.workplace.adress.latitude = workplace.latitude;
                loanClassObject.value.data.personal_info.workplace.adress.longitude = workplace.longitude;
                loanClassObject.value.data.personal_info.workplace.adress.ext_number = workplace.ext_number;
                loanClassObject.value.data.personal_info.workplace.adress.int_number = workplace.int_number;
                loanClassObject.value.data.personal_info.workplace.adress.postal_code = workplace.postal_code;
                loanClassObject.value.data.personal_info.workplace.adress.municipality = workplace.municipality;
                loanClassObject.value.data.personal_info.workplace.adress.residence_start = workplace.residence_start;
                loanClassObject.value.data.personal_info.workplace.adress.residence_status = workplace.residence_status;
                loanClassObject.value.data.personal_info.workplace.adress.between_street_one = workplace.between_street_one;
                loanClassObject.value.data.personal_info.workplace.adress.between_street_two = workplace.between_street_two;

                loanClassObject.value.data.credit_request_info.amount = creditData.value.amount.toString().trim();
                loanClassObject.value.data.credit_request_info.term = creditData.value.deadline.toString().toUpperCase().trim();
                loanClassObject.value.data.credit_request_info.payment_periodicity = creditData.value.frecuency.toString().toUpperCase().trim();
                loanClassObject.value.data.credit_request_info.credit_destination = creditData.value.credit_destination.toString().toUpperCase().trim();
                loanClassObject.value.data.credit_request_info.payment_method = creditData.value.payment_method.toString().toUpperCase().trim();

                loanClassObject.value.data.credit_owner_info.credit_benefit_statement = creditOwner.value.credit_owner.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.rfc = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.rfc : creditOwner.value.owner_rfc.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.name = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.name : creditOwner.value.owner_first_name.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.middle_name = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.middle_name : creditOwner.value.owner_second_name.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.paternal_name = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.paternal_name : creditOwner.value.owner_paternal_name.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.maternal_name = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.maternal_name : creditOwner.value.owner_maternal_name.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.gender = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.gender : creditOwner.value.owner_gender.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.nationality = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.nationality : (creditOwner.value.owner_birth_place.country.toString().toUpperCase() == 'MÉXICO')? 'MEXICANA': creditOwner.value.owner_birth_place.country.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.country = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.country_birth : creditOwner.value.owner_birth_place.country.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.state = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.state_birth : creditOwner.value.owner_birth_place.state.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.city = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.city_birth : creditOwner.value.owner_birth_place.city.toString().toUpperCase();
                loanClassObject.value.data.credit_owner_info.curp = (creditOwner.value.credit_owner == "para mí")? admissionRequestInformation.value.admission_information.curp : creditOwner.value.owner_curp.toString().toUpperCase();
                
                let contactInformation = admissionRequestInformation.value.admission_information.contact_info_attributes;
                
                let cellphone = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == "celular" );
                cellphone = contactInformation[cellphone].value;

                let telephone = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == "telefono particular" );
                telephone = contactInformation[telephone].value;

                let email = Object.keys(contactInformation).find(element => contactInformation[element].contact_type == "correo" );
                email = contactInformation[email].value;

                loanClassObject.value.data.credit_owner_info.email = (creditOwner.value.credit_owner == "para mí")? email : creditOwner.value.owner_contact_email.toString().toLowerCase();
                loanClassObject.value.data.credit_owner_info.cellphone = (creditOwner.value.credit_owner == "para mí")? cellphone : creditOwner.value.owner_contact_cellphone;
                loanClassObject.value.data.credit_owner_info.phone = (creditOwner.value.credit_owner == "para mí")? telephone : creditOwner.value.owner_contact_telephone;
                
                loanClassObject.value.data.credit_owner_info.has_had_public_positions = (creditOwner.value.credit_owner == "para mí")? publicOffice.has_had_public_positions : false;
                loanClassObject.value.data.credit_owner_info.any_relative_has_had_public_positions = (creditOwner.value.credit_owner == "para mí")? publicOfficeFamily.has_had_public_positions : false;

                loanClassObject.value.data.credit_provider_info.credit_benefit_statement = creditOwner.value.payer.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.rfc = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.rfc : creditOwner.value.payer_rfc.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.name = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.name : creditOwner.value.payer_first_name.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.middle_name = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.middle_name : creditOwner.value.payer_second_name.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.paternal_name = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.paternal_name : creditOwner.value.payer_paternal_name.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.maternal_name = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.maternal_name : creditOwner.value.payer_maternal_name.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.gender = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.gender : creditOwner.value.payer_gender.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.nationality = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.nationality : (creditOwner.value.payer_birth_place.country.toString().toUpperCase() == 'MÉXICO')? 'MEXICANA': creditOwner.value.payer_birth_place.country.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.country = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.country_birth : creditOwner.value.payer_birth_place.country.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.state = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.state_birth : creditOwner.value.payer_birth_place.state.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.city = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.city_birth : creditOwner.value.payer_birth_place.city.toString().toUpperCase();
                loanClassObject.value.data.credit_provider_info.curp = (creditOwner.value.payer == "por mí")? admissionRequestInformation.value.admission_information.curp : creditOwner.value.payer_curp.toString().toUpperCase();
                
                loanClassObject.value.data.credit_provider_info.email = (creditOwner.value.payer == "por mí")? email : creditOwner.value.payer_contact_email.toString().toLowerCase();
                loanClassObject.value.data.credit_provider_info.cellphone = (creditOwner.value.payer == "por mí")? cellphone : creditOwner.value.payer_contact_cellphone;
                loanClassObject.value.data.credit_provider_info.phone = (creditOwner.value.payer == "por mí")? telephone : creditOwner.value.payer_contact_telephone;
                
                loanClassObject.value.data.credit_provider_info.has_had_public_positions = (creditOwner.value.payer == "por mí")? publicOffice.has_had_public_positions : false;
                loanClassObject.value.data.credit_provider_info.any_relative_has_had_public_positions = (creditOwner.value.payer == "por mí")? publicOfficeFamily.has_had_public_positions : false;

                loanClassObject.value.data.documents_list = documentsUpload.value;
                
                return true;
            } catch (err) {
                return false;
            }
            
        }

        function showMessage(type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B"){
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        function goToHome(){
            router.push({ path: '/cuenta/principal' });
        }

        async function next() {
            isLoading.value = true;
            let setDataCompleted = setJSONData();
            if(setDataCompleted){

                let response = await registerCreditRequest();
                showMessage((response.status)? 'success': 'error', (response.status)? 'Aviso': 'Error', response.message);
                if(!response.status){
                    isLoading.value = false;
                    return;
                }

                goToHome();
            }
                
            isLoading.value = false;
        }

        return {
            isLoading,
            documentList,
            next,
            uploadFile,
            deleteFile,
            refresh
        };
    },
    components: {
        Loading
    }
}

</script>

<style scoped>
p {
    color: #485462;
}
p.label-input {
    color: #485462;
}
</style>